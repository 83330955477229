import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sourceCompany'
})
export class SourceCompanyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case "ps":
        return "PS";
        break;
      case "gvo":
        return "GVO";
        break;
      default:
        return "";
        break;
    }
  }

}
