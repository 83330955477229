import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CustomerService } from "../services/customer.service";
import { CustomerDetailDTO } from "../dto/customer-detail-dto";
import { GroupedCustomerSynonymEntriesDTO } from "../dto/grouped-customer-synonym-entries-dto";
import { GroupedSynonymDTO } from "../dto/grouped-synonym-dto";
import { MsalService } from "@azure/msal-angular";
import { CustomerUpdateDTO } from "../dto/customer-update-dto";
import { AddressUpdateDTO } from "../dto/address-update-dto";
import { AddressDetailDTO } from "../dto/address-detail-dto";
import { Observable, Subscription, empty, EMPTY } from "rxjs";
import { share, take, catchError } from "rxjs/operators";
import { AlertService } from "../services/alert.service";
import { Alert } from "../Alert";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-master-detail',
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss']
})

export class MasterDetailComponent implements OnInit, OnDestroy {
  customerDetailsResponse: CustomerDetailDTO;
  groupedSynonyms: GroupedSynonymDTO;

  customerUpdateDTO: CustomerUpdateDTO = new CustomerUpdateDTO();
  addressUpdateDTO: AddressUpdateDTO = new AddressUpdateDTO();
  currentGroupedCustomerSynonymEntriesDTO: GroupedCustomerSynonymEntriesDTO = new GroupedCustomerSynonymEntriesDTO();

  @ViewChild("changeCustomerNameInput") changeCustomerNameInput: ElementRef<HTMLInputElement>;
  selectableAddressesForMerge: AddressDetailDTO[];
  masterObservable: Observable<any>;
  synonymsObservable: Observable<any>;
  crefoObservable: Observable<any>;
  vatObservable: Observable<any>;
  nameObservable: Observable<any>;
  customerId: number;

  private paramSub: Subscription;

  constructor(
    private authService: MsalService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private alertService: AlertService
    )
    { }

  ngOnInit() {
    this.paramSub = this.route.params.subscribe(params => {
      this.customerId = params.id;
      this.loadCustomerDetails(this.customerId);
    });
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  saveVat(value: string) {
    const newVat = value;
    this.customerUpdateDTO = new CustomerUpdateDTO(this.customerDetailsResponse);
    this.customerUpdateDTO.vat = newVat;

    this.vatObservable = this.customerService.putCustomerUpdateDTO(this.customerDetailsResponse.id, this.customerUpdateDTO).pipe(share());

    this.vatObservable.subscribe(response => {
      this.customerDetailsResponse.vat = this.customerUpdateDTO.vat;
    });
  }

  saveCrefo(value: string) {
    const newCrefo = value;
    this.customerUpdateDTO = new CustomerUpdateDTO(this.customerDetailsResponse);
    this.customerUpdateDTO.crefonumber = newCrefo;

    this.crefoObservable = this.customerService.putCustomerUpdateDTO(this.customerDetailsResponse.id, this.customerUpdateDTO).pipe(share());

    this.crefoObservable.subscribe(response => {
      this.customerDetailsResponse.crefonumber = this.customerUpdateDTO.crefonumber;
    });
  }

  saveCustomerName(value: string) {
    const newName = value;
    this.customerUpdateDTO = new CustomerUpdateDTO(this.customerDetailsResponse);
    this.customerUpdateDTO.name = newName;

    this.nameObservable = this.customerService.putCustomerUpdateDTO(this.customerDetailsResponse.id, this.customerUpdateDTO).pipe(share());

    this.nameObservable.subscribe(response => {
      this.customerDetailsResponse.name = this.customerUpdateDTO.name;
    });
  }

  inputIsEmpty(element: HTMLInputElement) {
    let lengthNumber: number =  element.value.length;
    return (lengthNumber === 0);
  }


  loadGroupedSynonyms(): Subscription {
      this.synonymsObservable = this.customerService.getGroupedSynonymsByCustomerDetail(this.customerDetailsResponse).pipe(share());
      return this.synonymsObservable.subscribe(response => {
        this.groupedSynonyms = response;
      });
  }


  loadCustomerDetails(customerId: number): Subscription {
    this.masterObservable = this.customerService.getCustomerDetailById(customerId).pipe(catchError(e => EMPTY), share());
    return this.masterObservable.subscribe(customerDetailsResponse => {
      this.customerDetailsResponse = customerDetailsResponse;
      this.loadGroupedSynonyms();
    });
  }
}
