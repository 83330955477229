import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-master-detail-important-numbers',
  templateUrl: './master-detail-important-numbers.component.html',
  styleUrls: ['./master-detail-important-numbers.component.scss']
})
export class MasterDetailImportantNumbersComponent implements OnInit, OnChanges {
  inputValue: string = "";

  @Input() label: string = "Label";
  @Input() value: string = "";

  @Output() onSave: EventEmitter<string> = new EventEmitter();

  isLoading: boolean = false;
  @ViewChild("popover") popover: NgbPopover;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.value) {
      this.inputValue = this.value.toString();
    }
  }

  public get isEmpty(): boolean {
    const lengthNumber: number = this.inputValue.length;
    return (lengthNumber === 0);
  }

}
