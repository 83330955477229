import { ISelfReferenceLinks } from "./self-reference-links.interface";
import { CustomerSynonymDTO } from "./customer-synonym-dto";
import { AddressSynonymDTO } from "./address-synonym-dto";
import { IVerifyReferenceLinks } from "./IVerifyReferenceLinks.interface";

export class GroupedCustomerSynonymEntriesDTO {
    _links: IVerifyReferenceLinks;
    source: string;
    sourceCustomerIdentifier: string;
    operationTypes: {key: string, text: string}[];
    entryId: number;
    entryName: string;
    isVerified: boolean;
    isMovable: boolean;
    customerSynonyms: CustomerSynonymDTO[];
    addressSynonyms: AddressSynonymDTO[];
}
