import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-customer-match-card',
  templateUrl: './customer-match-card.component.html',
  styleUrls: ['./customer-match-card.component.scss']
})
export class CustomerMatchCardComponent implements OnInit {

  companies = [
    {
      
    },
    {

    },
    {

    }
  ];
  addresses = [
    {
      
    },
    {
  
    },
    {
  
    },
    {
      
    },
    {
  
    },
    {
  
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
