import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'customerIdFormatter'
})
export class CustomerIDFormatterPipe implements PipeTransform {
  maxLength = 8;
  transform(value: number | string): string {
    return this.FillValueWithZeroes(value);
  }

  FillValueWithZeroes(value: number | string): string {
    const numberOfZeroesToAdd = this.GetTheNumberOfZeroesNeededForValue(value);
    const zeroesString = this.GenerateZeroesString(numberOfZeroesToAdd);
    const stringValue = value.toString();

    const filledWithZeroes = zeroesString + stringValue;

    return filledWithZeroes;
  }

  GetTheNumberOfZeroesNeededForValue(value: number | string): number {
    const valueLength = value.toString().length;
    return Math.max(0, this.maxLength - valueLength);
  }

  GenerateZeroesString(numberOfZeroes: number): string {
    let zeroes = '';
    for (let i = 0; i < numberOfZeroes; i++) {
      zeroes += '0';
    }
    return zeroes;
  }
}
