import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { GroupedCustomerSynonymEntriesDTO } from "../dto/grouped-customer-synonym-entries-dto";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "../services/customer.service";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";

@Component({
  selector: 'app-grouped-synonyms',
  templateUrl: './grouped-synonyms.component.html',
  styleUrls: ['./grouped-synonyms.component.scss']
})
export class GroupedSynonymsComponent implements OnInit {
  @Input() groupedSynonym: GroupedCustomerSynonymEntriesDTO;
  @Output() moved: EventEmitter<number> = new EventEmitter();
  verifyObservable: Observable<any>;
  moveObservable: Observable<any>;
  creatingCustomerObservable: Observable<any>;
  movingToCustomerObservable: Observable<any>;


  constructor(private customerService: CustomerService, public modalService: NgbModal) { }

  ngOnInit() {
  }

  verifyButtonClicked(groupedCustomerSynonymEntry: GroupedCustomerSynonymEntriesDTO){
    this.verifyObservable = this.customerService.postIsVerifiedOfGroupedCustomerEntry(
      groupedCustomerSynonymEntry
    ).pipe(share());
    this.verifyObservable.subscribe(response => {
      groupedCustomerSynonymEntry.isVerified = true;
    });
  }

  creatingNewCustomerWithEntry(creatingCustomerObservable: Observable<any>){
    this.creatingCustomerObservable = creatingCustomerObservable;
  }

  movingEntryToNewCustomer(movingEntryToNewObservable: Observable<any>){
    this.movingToCustomerObservable = movingEntryToNewObservable;
  }
}
