import { Component, OnInit, OnDestroy, HostListener, Directive } from "@angular/core";
import { CustomerService } from "../services/customer.service";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, flatMap } from "rxjs/operators";
import { CustomerSummaryCollectionDTO } from "../dto/customer-summary-collection-dto";
import { Router } from "@angular/router";
@Component({
  selector: 'app-master-list',
  templateUrl: './master-list.component.html',
  styleUrls: ['./master-list.component.scss']
})
export class MasterListComponent implements OnInit {
  customerSummaryResponse: CustomerSummaryCollectionDTO = null;
  constructor(private customerService: CustomerService, private router: Router) { }

  private keyUpSubject: Subject<string> = new Subject();
  sub: Subscription;

  ngOnInit() {
  }

  routeToCustomer(customerId: number) {
    this.router.navigate(["/master-detail", customerId]);
  }

}
