import { AddressDetailDTO } from "./address-detail-dto";

export class AddressUpdateDTO {
  id: number;
  street: string;
  zip: string;
  city: string;
  country: string;
  constructor(addressDetail: AddressDetailDTO = new AddressDetailDTO()){
    this.id = addressDetail.id;
    this.street = addressDetail.street;
    this.zip = addressDetail.zip;
    this.city = addressDetail.city;
    this.country = addressDetail.country;
  }
}
