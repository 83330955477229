import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CustomerSummaryCollectionDTO } from "../dto/customer-summary-collection-dto";
import { environment } from "src/environments/environment";
import { throwError, Observable, of } from "rxjs";
import { GroupedCustomerSynonymEntriesDTO } from "../dto/grouped-customer-synonym-entries-dto";
import { CustomerDetailDTO } from "../dto/customer-detail-dto";
import { GroupedSynonymDTO } from "../dto/grouped-synonym-dto";
import { CustomerUpdateDTO } from "../dto/customer-update-dto";
import { AddressUpdateDTO } from "../dto/address-update-dto";
import { AddressDetailDTO } from "../dto/address-detail-dto";
import { tap, map } from "rxjs/operators";
import { AddressDetailCollectionDTO } from "../dto/address-detail-collection-dto";
import { EntryMoveInfoDto } from "../dto/entry-move-info-dto";

class Cache<T> {
  [key: string]: CacheContent<T>;
}

class CacheContent<T> {
  value: T;
  validUntil: number;
}

@Injectable({
  providedIn: "root"
})
export class CustomerService {
  private cache: Cache<CustomerSummaryCollectionDTO>;
  private readonly minutesToCache = 5;

  private customerCollectionUrl = `${environment.apiHost}/${environment.apiBaseUrl}/customers`;
  private addressCollectionUrl = `${environment.apiHost}/${environment.apiBaseUrl}/addresses`;
  private synonymsCollectionUrl = `${environment.apiHost}/${environment.apiBaseUrl}/synonyms`;
  private entriesCollectionUrl = `${environment.apiHost}/${environment.apiBaseUrl}/entries`;

  constructor(private http: HttpClient) {
    this.cache = new Cache();
  }

  public getCustomerDetail(id: number) {
    return this.http.get<CustomerSummaryCollectionDTO>(this.buildDetailUrl(id));
  }
  public getGroupedSynonymsByCustomerDetail(customerDetail: CustomerDetailDTO) {
    return this.http.get<GroupedSynonymDTO>(
      this.buildGroupedSynonymsUrlByCustomerDetail(customerDetail)
    );
  }
  public getCustomerSynonyms(take = 50, skip = 0) {
    return this.http.get<CustomerSummaryCollectionDTO>(
      this.buildSummaryCollectionUrl(take, skip)
    );
  }

  public getCustomerSummaryCollection(take = 50, skip = 0, onlyNonVerified = false) {
    const cachedValue = this.getCachedCustomerSummary(take, skip, null, onlyNonVerified);
    if (cachedValue) {
      return cachedValue;
    }

    return this.http.get<CustomerSummaryCollectionDTO>(this.buildSummaryCollectionUrl(take, skip, onlyNonVerified)).pipe(
      tap(csc => {
        this.setCustomerSummaryCache(csc, take, skip, null, onlyNonVerified);
      })
    );
  }

  // public getNextCustomerSummaryCollection(customerSummaryCollection: CustomerSummaryCollectionDTO) {
  //   return this.http.get<CustomerSummaryCollectionDTO>(
  //     customerSummaryCollection._links.next
  //   );
  // }

  public getCustommerSummaryBySearch(query: string, take = 50, skip = 0, onlyNonVerified = false) {
    const cachedValue = this.getCachedCustomerSummary(take, skip, query, onlyNonVerified);
    if (cachedValue) {
      return cachedValue;
    }

    return this.http.get<CustomerSummaryCollectionDTO>(
      this.buildSummaryCollectionQueryUrl(query, take, skip, onlyNonVerified)
    ).pipe( tap(csc => {
        this.setCustomerSummaryCache(csc, take, skip, query, onlyNonVerified);
    }));
  }

  public getCustomerDetailById(id: number) {
    return this.http.get<CustomerDetailDTO>(this.buildDetailUrl(id));
  }

  public getCustomerAddresses(customerId: number): Observable<AddressDetailDTO[]> {
    return this.http
      .get<AddressDetailCollectionDTO>(this.buildAddressByCustomerIdUrl(customerId))
      .pipe(map(addressCollection => addressCollection._embedded.addresses));
  }

  public getEntryMoveInfo(entryId: number): Observable<EntryMoveInfoDto> {
    return this.http.get<EntryMoveInfoDto>(this.buildEntryMoveInfoUrl(entryId));
  }

  public postIsVerifiedOfGroupedCustomerEntry(
    groupedCustomerSynonymEntry: GroupedCustomerSynonymEntriesDTO
  ) {
    return this.http.post(
      this.buildVerifyGroupedCustomerSynonymEntryUrl(
        groupedCustomerSynonymEntry
      ),
      {}
    ).pipe(tap(_ => this.clearCache()));
  }

  public putCustomerUpdateDTO(
    customerEntityId: number,
    customerUpdateDTO: CustomerUpdateDTO
  ) {
    return this.http.put(
      this.buildCustomerUpdateDTOUrl(customerEntityId),
      customerUpdateDTO
    ).pipe(tap(_ => this.clearCache()));
  }

  public putAddressUpdateDTO(
    addressEnityId: number,
    addressUpdateDTO: AddressUpdateDTO
  ) {
    return this.http.put<AddressDetailDTO>(
      this.buildAddessUpdateDTOUrl(addressEnityId),
      addressUpdateDTO
    ).pipe(tap(_ => this.clearCache()));
  }

  public moveCustomerEntryToCustomerEntity(
    groupedCustomerSynonymEntriesId: number,
    targetCustomerId: number,
    mergeWithAddressEntityId?: number,
    force: boolean = false
  ) {
    return this.http.post(
      this.buildMoveCustomerEntryToNewCustomerEntityUrl(
        groupedCustomerSynonymEntriesId,
        targetCustomerId,
        mergeWithAddressEntityId,
        force
      ),
      {}
    ).pipe(tap(_ => this.clearCache()));
  }

  public createNewCustomerAndMoveEntryToIt(
    entryId: number,
    force: boolean = false
  ) {
    return this.http.post(
      this.buildCreateNewCustomerAndMoveEntryToItUrl(entryId, force),
      {}
    ).pipe(tap(_ => this.clearCache()));
  }

  public mergeAddressEntities(sourceAddressEntityId: number, targetAddressEntityId: number) {
    return this.http.post(
      this.buildAddressEntityMergeUrl(
        sourceAddressEntityId,
        targetAddressEntityId
        ),
        {}
    ).pipe(tap(_ => this.clearCache()));
  }

  public mergeCustomerEntities(
    sourceCustomerEntityId: number,
    targetCustomerEntityId: number) {
    return this.http.post(this.buildMergeCustomerEntitiesUrl(sourceCustomerEntityId, targetCustomerEntityId), {})
                    .pipe(tap(_ => this.clearCache()));
  }

  private buildMergeCustomerEntitiesUrl(
    sourceCustomerEntityId: number,
    targetCustomerEntityId: number) {
    return this.joinUrlSegments(
      environment.apiHost,
      environment.apiBaseUrl,
      `/customers/merge?source=${sourceCustomerEntityId}&target=${targetCustomerEntityId}`
    );
  }

  private buildAddressEntityMergeUrl(sourceAddressEntityId: number, targetAddressEntityId: number) {
    return this.joinUrlSegments(
      environment.apiHost,
      environment.apiBaseUrl,
      `/addresses/merge?source=${sourceAddressEntityId}&target=${targetAddressEntityId}`
    );
  }
  private buildMoveCustomerEntryToNewCustomerEntityUrl(
    customerEntryId: number,
    customerEntityId: number,
    mergeWithAddressEntityId?: number,
    force: boolean = false,
  ) {

    const addressParameterUrlSnippet = (mergeWithAddressEntityId) ? `&addressId=${mergeWithAddressEntityId}` : "";

    const url = this.joinUrlSegments(environment.apiHost, environment.apiBaseUrl,
      `/entries/move?entryId=${customerEntryId}&customerId=${customerEntityId}${addressParameterUrlSnippet}&force=${force}`);
    return url;
  }


  private buildCreateNewCustomerAndMoveEntryToItUrl(
    entryId: number,
    force: boolean = false
  ) {
    const url = this.joinUrlSegments(environment.apiHost, environment.apiBaseUrl,
      `/entries/createCustomer?entryId=${entryId}&force=${force}`);
    return url;
  }

  private buildSummaryCollectionQueryUrl(query: string, take = 50, skip = 0, onlyNonVerified = false) {
    const encodedQuery = encodeURIComponent(query);
    return `${
      this.customerCollectionUrl.toString()
    }?query=${encodedQuery}&take=${take}&skip=${skip}&onlyNonVerified=${onlyNonVerified}`;
  }

  private buildSummaryCollectionUrl(take = 50, skip = 0, onlyNonVerified = false) {
    return `${this.customerCollectionUrl}?take=${take}&skip=${skip}&onlyNonVerified=${onlyNonVerified}`;
  }

  private buildGroupedSynonymsUrlByCustomerDetail(
    customerSummary: CustomerDetailDTO
  ) {
    return this.joinUrlSegments(
      environment.apiHost.toString(),
      customerSummary._links.synonyms.toString()
    );
  }

  private buildDetailUrl(id: number) {
    return this.joinUrlSegments(this.customerCollectionUrl, id.toString());
  }

  private buildAddressByCustomerIdUrl(customerId: number) {
    return `${this.addressCollectionUrl}?customerId=${customerId}`;
  }

  private buildEntryMoveInfoUrl(entryId: number) {
    return `${this.entriesCollectionUrl}/${entryId}/moveinfo`;
  }

  private buildVerifyGroupedCustomerSynonymEntryUrl(
    groupedCustomerSynonymEntry: GroupedCustomerSynonymEntriesDTO
  ) {
    return (
      this.joinUrlSegments(
        environment.apiHost.toString(),
        groupedCustomerSynonymEntry._links.verify.toString()
      )
    );
  }

  private buildCustomerUpdateDTOUrl(customerEntityId: number) {
    return this.joinUrlSegments(this.customerCollectionUrl.toString(), customerEntityId.toString());
  }

  private buildAddessUpdateDTOUrl(addressEnityId: number) {
    return this.joinUrlSegments(environment.apiHost, environment.apiBaseUrl, "/addresses", addressEnityId.toString());
  }

  private joinUrlSegments(s1: string, ...sArgs: any[]) {
    if (sArgs.length > 0) {
      for (const s of sArgs) {
        s1 = this.join(s1, s);
      }
    }

    return s1;
  }

  private join(s1: string, s2: any): string {
    s2 = "" + s2;
    if (s1.endsWith("/")) {
      if (s2.startsWith("/")) {
        return s1 + s2.substr(1);
      } else {
        return s1 + s2;
      }
    } else if (s2.startsWith("/")) {
      return s1 + s2;
    } else {
      return s1 + "/" + s2;
    }
  }


  private getCachedCustomerSummary(take: number = 50, skip: number = 0, query: string = null, onlyNonVerified: boolean = false)
                                   : Observable<CustomerSummaryCollectionDTO> {
    const cachedValue = this.cache[`${take}-${skip}-${query}-${onlyNonVerified}`];
    if (cachedValue && cachedValue.validUntil > Date.now()) {
      return of(cachedValue.value);
    } else {
      return null;
    }
  }

  private setCustomerSummaryCache(valueToCache: CustomerSummaryCollectionDTO,
                                  take: number = 50, skip: number = 0, query: string = null, onlyNonVerified: boolean = false) {
    this.cache[`${take}-${skip}-${query}-${onlyNonVerified}`] = {
      value: valueToCache,
      validUntil: Date.now() + (this.minutesToCache * 60000)
    };
  }

  private clearCache() {
    this.cache = new Cache();
  }
}
