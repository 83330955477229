import { ISelfReferenceLinks } from "./self-reference-links.interface";

export class AddressDetailDTO {
  _links: ISelfReferenceLinks;

  id: number;
  street: string;
  zip: string;
  city: string;
  country: string;
  isMovable: boolean;
}
