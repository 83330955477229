import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MasterListComponent } from "./master-list/master-list.component";
import { MasterDetailComponent } from "./master-detail/master-detail.component";
import { MsalGuard } from "@azure/msal-angular";

const routes: Routes = [
  {
    path: "master-liste",
    component: MasterListComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "master-detail/:id",
    component: MasterDetailComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "",
    redirectTo: "/master-liste",
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: "/master-liste",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
