import { Injectable } from '@angular/core';
import { Alert } from "../Alert";
import { Subject, Observable } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alerts$: Observable<Alert>;
  private alertsSubject: Subject<Alert> = new Subject();

  private SERVER_ALERT_MESSAGE: string = "Autsch! Der Server konnte deine Anfrage nicht bearbeiten.";
  private CLIENT_ALERT_MESSAGE: string = "Woops! Bei dir ist etwas Schief gelaufen.";

  constructor() {
    this.alerts$ = this.alertsSubject.asObservable();
  }

  addAlert(alert: Alert) {
    this.alertsSubject.next(alert);
  }

  addServerErrorAlert() {
    this.addAlert(new Alert("danger", this.SERVER_ALERT_MESSAGE));
  }

  addClientErrorAlert() {
    this.addAlert(new Alert("danger", this.CLIENT_ALERT_MESSAGE));
  }

  turnErrorIntoAlert(error: HttpErrorResponse) {
    switch (error.status.toString().substring(0, 1)) {
      case "4":
        this.addClientErrorAlert();
        break;
      case "5":
        this.addServerErrorAlert();
        break;
      default:
        break;
    }
  }
}
