import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddressDetailDTO } from "../dto/address-detail-dto";

@Component({
  selector: 'app-merge-address-form',
  templateUrl: './merge-address-form.component.html',
  styleUrls: ['./merge-address-form.component.scss']
})
export class MergeAddressFormComponent implements OnInit {

  targetAddressEntityIdForMove: number = 0;
  @Input() addressDetailsDTO: AddressDetailDTO[];
  @Input() newSelectable = false;
  @Output() addressSelected: EventEmitter<number> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  onSelectAddress(addressId: number) {
    this.addressSelected.emit(addressId);
  }

}
