import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { CustomerService } from "../services/customer.service";
import { share } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: 'app-merge-customer',
  templateUrl: './merge-customer.component.html',
  styleUrls: ['./merge-customer.component.scss']
})
export class MergeCustomerComponent implements OnInit {
  @Input() customerEntityId: number;
  mergeObservable: Observable<any>;
  targetEntityId: number;
  constructor(private customerService: CustomerService, public modalService: NgbModal, private router: Router) { }

  ngOnInit() {
  }

  mergeWithCustomerEntity(targetEntityId: number) {
    this.targetEntityId = targetEntityId;
    this.mergeObservable = this.customerService.mergeCustomerEntities(this.customerEntityId, targetEntityId).pipe(share());
    this.mergeObservable.subscribe(response => {
    });
  }

  routeToTargetEntity() {
    this.modalService.dismissAll();
    this.router.navigate(["/master-detail", this.targetEntityId]);
  }
}
