import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { CustomerService } from "../services/customer.service";
import { share } from "rxjs/operators";
import { AddressDetailDTO } from "../dto/address-detail-dto";
import { AlertService } from "../services/alert.service";
import { Alert } from "../Alert";
import { EntryMoveInfoDto } from "../dto/entry-move-info-dto";

@Component({
  selector: "app-move-entry",
  templateUrl: "./move-entry.component.html",
  styleUrls: ["./move-entry.component.scss"]
})
export class MoveEntryComponent implements OnInit {
  @Input() entryId: number;
  @Output() moved: EventEmitter<number> = new EventEmitter();

  targetAddressEntityId: number;
  targetCustomerEntityId: number;
  addresses: AddressDetailDTO[];
  moveInfo: EntryMoveInfoDto;

  loadMoveInfo$: Observable<EntryMoveInfoDto>;
  moveToNewCustomerObservable: Observable<any>;
  moveToExistingCustomerObservable: Observable<any>;
  constructor(
    private customerService: CustomerService,
    public modalService: NgbModal,
    private alertService: AlertService
  ) {}

  ngOnInit() {}

  createNewCustomerAndMoveEntryToIt(entryId: number) {
    this.moveToNewCustomerObservable = this.customerService
      .createNewCustomerAndMoveEntryToIt(entryId, this.isForceMoveRequired())
      .pipe(share());
    this.moveToNewCustomerObservable.subscribe(response => {
      this.moved.emit(response);
      this.alertService.addAlert(
        new Alert("success", `Neuer Kunde wurde mit Id '${response}' angelegt.`)
      );
    });
    this.modalService.dismissAll();
  }

  moveToExistingCustomer(
    entryId: number,
    targetCustomerEntityId: number,
    targetAddressEntityId: number
  ) {
    if (targetCustomerEntityId !== undefined) {
      this.moveToExistingCustomerObservable = this.customerService
        .moveCustomerEntryToCustomerEntity(
          entryId,
          targetCustomerEntityId,
          targetAddressEntityId,
          this.isForceMoveRequired()
        )
        .pipe(share());
      this.moveToExistingCustomerObservable.subscribe(response => {
        this.moved.emit(targetCustomerEntityId);
        this.alertService.addAlert(
          new Alert(
            "success",
            `Eintrag wurde zu Kunde '${targetCustomerEntityId}' verschoben.`
          )
        );
      });
    } else {
    }
    this.modalService.dismissAll();
  }

  loadCustomerAddressesOfCustomerEntityById(customerEnityId: number) {
    this.customerService
      .getCustomerAddresses(customerEnityId)
      .subscribe(addresses => {
        this.addresses = addresses;
      });
  }

  loadMoveInfo() {
    this.loadMoveInfo$ = this.customerService
      .getEntryMoveInfo(this.entryId)
      .pipe(share());
    this.loadMoveInfo$.subscribe(
      mi => (this.moveInfo = mi),
      err => (this.moveInfo = { moveType: "error", addresses: null })
    );
  }

  isNotMovable(): boolean {
    return (
      this.moveInfo &&
      (this.moveInfo.moveType === "none" || this.moveInfo.moveType === "error")
    );
  }

  isMovableWithAddress(): boolean {
    return this.moveInfo && this.moveInfo.moveType === "with address";
  }

  hasMoveError(): boolean {
    return this.moveInfo && this.moveInfo.moveType === "error";
  }

  isForceMoveRequired(): boolean {
    return this.isMovableWithAddress();
  }
}
