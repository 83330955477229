import { Pipe, PipeTransform } from "@angular/core";
import { AddressSummaryDTO } from "./dto/address-summary-dto";

@Pipe({
  name: 'addressList'
})
export class AddressListPipe implements PipeTransform {

  transform(value: AddressSummaryDTO[]): any {
    return this.seperateItemsWithComma(value);
  }

  seperateItemsWithComma(addresses: AddressSummaryDTO[]): string {
    // return (item._embedded.addresses.length > 0) ? item._embedded.addresses.map(a => a.city).join(", ") : "Keine Straße vorhanden";
    return (addresses.length > 0) ? addresses.map(a => a.city).join(", ") : "Keine Straße vorhanden";
  }
}
