import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { GeneralNavigationComponent } from "./general-navigation/general-navigation.component";
import { CustomerMatchCardComponent } from "./customer-match-card/customer-match-card.component";
import { CheckboxBigComponent } from "./checkbox-big/checkbox-big.component";
import { CheckboxBigWithLabelComponent } from "./checkbox-big-with-label/checkbox-big-with-label.component";
import { MasterListComponent } from "./master-list/master-list.component";
import { MasterDetailComponent } from "./master-detail/master-detail.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { MsalModule, MsalInterceptor } from "@azure/msal-angular";
import { FormsModule } from "@angular/forms";
import { CustomerIDFormatterPipe } from "./customer-id-formatter.pipe";
import { environment } from "src/environments/environment";
import { AddressListPipe } from "./address-list.pipe";
import { SelectCustomerFormComponent } from './select-customer-form/select-customer-form.component';
import { MergeAddressFormComponent } from './merge-address-form/merge-address-form.component';
import { AddressBoxComponent } from './address-box/address-box.component';
import { LoadingComponent } from './loading/loading.component';
import { MasterDetailImportantNumbersComponent } from './master-detail-important-numbers/master-detail-important-numbers.component';
import { MasterDetailCustomerNameComponent } from './master-detail-customer-name/master-detail-customer-name.component';
import { GroupedSynonymsComponent } from './grouped-synonyms/grouped-synonyms.component';
import { MergeCustomerComponent } from './merge-customer/merge-customer.component';
import { MoveEntryComponent } from './move-entry/move-entry.component';
import { SelectAddressFormComponent } from './select-address-form/select-address-form.component';
import { SourceCompanyPipe } from './source-company.pipe';
import { AlertsComponent } from './alerts/alerts.component';

@NgModule({
  declarations: [
    AppComponent,
    GeneralNavigationComponent,
    CustomerMatchCardComponent,
    CheckboxBigComponent,
    CheckboxBigWithLabelComponent,
    MasterListComponent,
    MasterDetailComponent,
    CustomerIDFormatterPipe,
    AddressListPipe,
    SelectCustomerFormComponent,
    MergeAddressFormComponent,
    AddressBoxComponent,
    LoadingComponent,
    MasterDetailImportantNumbersComponent,
    MasterDetailCustomerNameComponent,
    GroupedSynonymsComponent,
    MergeCustomerComponent,
    MoveEntryComponent,
    SelectAddressFormComponent,
    SourceCompanyPipe,
    AlertsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    MsalModule.forRoot({
      clientID: environment.clientId,
      redirectUri: environment.outhRedirectUrl,
      authority: "https://login.microsoftonline.com/1c2b8ed7-dafe-4820-ab1e-23ce67c5326e",
      protectedResourceMap: [ [ environment.apiHost, environment.requiredScopes ] ]
    })
  ],
  exports: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
