import { CustomerDetailDTO } from "./customer-detail-dto";

export class CustomerUpdateDTO {
  id: number;
  name: string;
  crefonumber: string;
  vat: string;
  constructor(customerDetail?: CustomerDetailDTO) {
    if(customerDetail == null) {
      customerDetail = new CustomerDetailDTO();
    }
    this.id = customerDetail.id;
    this.name = customerDetail.name;
    this.vat = customerDetail.vat;
    this.crefonumber = customerDetail.crefonumber;
  }
}
