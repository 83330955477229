import { ISelfReferenceLinks } from "./self-reference-links.interface";
import { AddressDetailDTO } from "./address-detail-dto";

export class CustomerDetailLinks implements ISelfReferenceLinks {
  self: string;
  synonyms: string;
}

export class CustomerDetailDTO {
  _links: CustomerDetailLinks;

  id: number;
  name: string;
  crefonumber: string;
  vat: string;

  _embedded: {
    addresses: AddressDetailDTO[];
  };
}
