import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-master-detail-customer-name',
  templateUrl: './master-detail-customer-name.component.html',
  styleUrls: ['./master-detail-customer-name.component.scss']
})
export class MasterDetailCustomerNameComponent implements OnInit {
  @Input() value: string;
  @Output() onSave: EventEmitter<string> = new EventEmitter();
  inputValue: string = "";
  constructor() { }

  ngOnInit() {
  }

  public get isEmpty(): boolean {
    const lengthNumber: number = this.inputValue.length;
    return (lengthNumber === 0);
  }


}
