import { Component, OnInit } from '@angular/core';
import { AlertService } from "../services/alert.service";
import { Alert } from "../Alert";


class AlertView extends Alert {
  closing: boolean = false;

  constructor(alert: Alert) {
    super(alert.type, alert.message);
  }
}
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  alerts: AlertView[] = [];
  constructor(public alertService: AlertService) {
    this.alertService.alerts$.subscribe(alert => {
      this.addAlert(new AlertView(alert));
    });
  }

  ngOnInit() {
  }

  addAlert(alert: AlertView) {
    this.alerts.push(alert);
    setTimeout(() => {
      this.removeAlert(alert);
    }, 10000);
  }

  // removeAlert(alert: Alert) {
  //   this.alertService.removeAlert(alert);
  // }

  // removeAlertByIndex(index: number) {
  //   this.alertService.removeAlertByIndex(index);
  // }


  removeAlert(alert: AlertView) {
    alert.closing = true;
    setTimeout(() => {
      this.removeAlertByIndex(this.alerts.indexOf(alert));
    }, 300);
  }

  removeAlertByIndex(index: number) {
    this.alerts.splice(index, 1);
  }

}