import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddressDetailDTO } from "../dto/address-detail-dto";

@Component({
  selector: 'app-select-address-form',
  templateUrl: './select-address-form.component.html',
  styleUrls: ['./select-address-form.component.scss']
})
export class SelectAddressFormComponent implements OnInit {
  @Input() addresses: AddressDetailDTO[];
  @Output() addressSelected: EventEmitter<number> = new EventEmitter();

  targetAddressEntityIdForMove: number;
  constructor() { }

  ngOnInit() {
  }
}
