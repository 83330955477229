import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'app-general-navigation',
  templateUrl: './general-navigation.component.html',
  styleUrls: ['./general-navigation.component.scss']
})
export class GeneralNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
