import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AddressDetailDTO } from "../dto/address-detail-dto";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AddressUpdateDTO } from "../dto/address-update-dto";
import { CustomerService } from "../services/customer.service";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";

@Component({
  selector: 'app-address-box',
  templateUrl: './address-box.component.html',
  styleUrls: ['./address-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressBoxComponent implements OnInit {
  @Input() address: AddressDetailDTO;
  @Input() addresses: AddressDetailDTO[];
  @Output() merged: EventEmitter<number> = new EventEmitter();

  selectableAddressesForMerge: AddressDetailDTO[];
  addressUpdateDTO: AddressUpdateDTO;

  selectedSourceAddressEntityForMerge: number;
  selectedTargetAddressEntityIdForMerge: number;

  @ViewChild("MergeAddressModal") MergeAddressModal: NgbModalRef;

  addressUpdateObservable: Observable<any>;


  currentEditingAddress: AddressDetailDTO = new AddressDetailDTO();
  constructor(private customerService: CustomerService, private modalService: NgbModal) { }

  ngOnInit() {
    this.currentEditingAddress = this.address;
  }

  openMergeAddressModal(addressDetail: AddressDetailDTO) {
    this.selectedSourceAddressEntityForMerge = addressDetail.id;
    this.selectableAddressesForMerge = this.addresses.filter(a => a.id !== this.selectedSourceAddressEntityForMerge);
    this.modalService.open(this.MergeAddressModal, { size: "lg" });
  }

  addressClicked(address: AddressDetailDTO) {
    this.currentEditingAddress = (JSON.parse(JSON.stringify(address)));
  }

  saveAddressButtonClicked() {
    this.addressUpdateDTO = new AddressUpdateDTO(this.currentEditingAddress);
    this.addressUpdateObservable = this.customerService.putAddressUpdateDTO(this.addressUpdateDTO.id, this.addressUpdateDTO).pipe(share());
    console.log("Update Address");

    this.addressUpdateObservable.subscribe(response => {
      const addressDetail = this.addresses.find(a => a.id === response.id);
      this.addresses.splice(
        this.addresses.indexOf(addressDetail),
        1,
        response
      );
    });
  }

  addressForMergeSelected(addressEntityId: number) {
    this.selectedTargetAddressEntityIdForMerge = addressEntityId;
  }

  mergeAddresses(sourceAddressId: number, targetAddressId: number) {
    this.customerService.mergeAddressEntities(sourceAddressId, targetAddressId).subscribe(response => {
      const i = this.addresses.findIndex(a => a.id === sourceAddressId);
      this.addresses.splice(i, 1);
      this.modalService.dismissAll();
      this.merged.emit(targetAddressId);
    }, error => {
    });
  }

  public addressHasEmptyFields(address: AddressDetailDTO): boolean {
    return (
      address.city.length === 0 ||
      address.country.length === 0 ||
      address.street.length === 0 ||
      address.zip.length === 0
    );
  }

}
