import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { CustomerService } from "../services/customer.service";
import { Subject, Subscription, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, flatMap, share } from "rxjs/operators";
import { CustomerSummaryCollectionDTO } from "../dto/customer-summary-collection-dto";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "../services/alert.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-select-customer-form',
  templateUrl: './select-customer-form.component.html',
  styleUrls: ['./select-customer-form.component.scss']
})
export class SelectCustomerFormComponent implements OnInit, OnDestroy {
  @Input() newCustomerSelectable = false;
  @Input() cols = 2;
  @Input() initialCustomerToLoad = 50;
  @Input() lazyLoadingAmount = 50;
  @Input() lazyLoad = false;
  @Input() highlightUnverified = true;
  @Input() rememberFilter = false;

  @Output() customerSelected: EventEmitter<number> = new EventEmitter();
  @Output() newCustomer: EventEmitter<number> = new EventEmitter();

  @ViewChild("SelectAddressModal") selectAddressModal: NgbModalRef;

  private keyUpSubject: Subject<string> = new Subject();
  sub: Subscription;

  customerSummaryResponse: CustomerSummaryCollectionDTO = null;
  targetAddressEntityIdForMove: number = 0;
  targetCustomerEntityIdForMove: number = 0;
  isLazyLoading: boolean = false;
  lazyLoadingObservable: Observable<any>;
  initialLoadingObservable: Observable<any>;
  searchLoadingObservable: Observable<any>;
  currentSearchQuery: string = "";
  onlyNonVerified: boolean = false;



  @HostListener("window:scroll", ["$event"]) onScroll(event) {
    if (this.lazyLoad) {
      const documentHeight = (document.body.offsetHeight - window.innerHeight);
      const scrolledY = window.pageYOffset;
      const lazyLoadingThreshholdInPixel = 1000;
      if (!this.isLazyLoading && documentHeight - scrolledY < lazyLoadingThreshholdInPixel) {
        const take = this.lazyLoadingAmount;
        const skip = this.customerSummaryResponse._embedded.customers.length;
        this.isLazyLoading = true;
        if (this.currentSearchQuery === "") {
          this.lazyLoadingObservable = this.loadCustomers(take, skip, this.currentSearchQuery, this.onlyNonVerified);
        } else {
          this.lazyLoadingObservable = this.loadCustomers(this.initialCustomerToLoad, skip, this.currentSearchQuery, this.onlyNonVerified);
        }
        this.lazyLoadingObservable.subscribe((response) => {
          this.customerSummaryResponse._embedded.customers =
            this.customerSummaryResponse._embedded.customers.concat(response._embedded.customers);
          this.isLazyLoading = false;
        }, error => {
          this.isLazyLoading = false;
        });
      }
    }
  }

  constructor(
    private customerService: CustomerService,
    private location: Location,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    if (this.rememberFilter) {
      const onlyNonVerified = JSON.parse(this.route.snapshot.queryParamMap.get("nonVerified"));
      if (onlyNonVerified) {
        this.onlyNonVerified = onlyNonVerified;
      }
      const currentSearchQuery = this.route.snapshot.queryParamMap.get("searchQuery");
      if (currentSearchQuery) {
        this.currentSearchQuery = currentSearchQuery;
      }
    }

    this.initialLoadingObservable = this.loadCustomers(this.initialCustomerToLoad, 0, this.currentSearchQuery, this.onlyNonVerified);
    this.initialLoadingObservable.subscribe((response) => {
      this.customerSummaryResponse = response;
    });

    this.sub = this.keyUpSubject.pipe(share(), debounceTime(500), distinctUntilChanged(), flatMap(value => {
      this.searchLoadingObservable = this.loadCustomers(this.initialCustomerToLoad, 0, this.currentSearchQuery, this.onlyNonVerified, true);
      return this.searchLoadingObservable;
    })).subscribe((response: CustomerSummaryCollectionDTO) => {
      this.customerSummaryResponse = response;
    }, error => {
      this.alertService.turnErrorIntoAlert(error);
    });
  }

  addFilterParamsToUrl() {

    if (this.rememberFilter) {
      this.updateUrl([
        { key: "searchQuery", value: this.currentSearchQuery },
        { key: "nonVerified", value: this.onlyNonVerified.toString() }
      ]);
    }
  }

  updateUrl(urlParams: { key: string, value: string }[]) {
    let urlParamsString = "";
    urlParams.forEach((element, index) => {
      if (index > 0) {
        urlParamsString += `&`;
      }
      urlParamsString += `${element.key}=${element.value}`;
    });

    this.location.go(`${this.route.snapshot.url.join("/")}?${urlParamsString}`);
  }

  loadCustomers(take: number, skip: number, searchQuery: string = null, onlyNonVerified: boolean = false, addToUrl: boolean = false) {
    let loadObservable: Observable<any>;
    if (!searchQuery) {
      loadObservable = this.customerService.getCustomerSummaryCollection(take, skip, onlyNonVerified).pipe(share());
    } else {
      loadObservable = this.customerService.getCustommerSummaryBySearch(searchQuery, take, skip, onlyNonVerified).pipe(share());
    }
    if (addToUrl) {
      this.addFilterParamsToUrl();
    }
    //this.location.replaceState(`/master-liste?searchQuery=${this.currentSearchQuery}&nonVerified=${this.onlyNonVerified}`);
    return loadObservable;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public async SearchArgumentChange(e): Promise<void> {
    const value: string = e.target.value;
    this.keyUpSubject.next(value);
  }

  onlyNonVerifiedChanged() {
    this.initialLoadingObservable = this.loadCustomers(this.initialCustomerToLoad, 0, this.currentSearchQuery, this.onlyNonVerified, true);
    this.initialLoadingObservable.subscribe(response => {
      this.customerSummaryResponse = response;
    });
  }
}
