import { Component, OnInit, Input, Output } from "@angular/core";
import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import { EventEmitter } from "events";
import { ChangeDetectionStrategy } from "@angular/compiler/src/core";

@Component({
  selector: 'app-checkbox-big',
  templateUrl: './checkbox-big.component.html',
  styleUrls: ['./checkbox-big.component.scss']
})
export class CheckboxBigComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }

}
