import { Component, SimpleChanges, OnInit, Output, Input, OnChanges, OnDestroy, TemplateRef, EventEmitter } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { AlertService } from "../services/alert.service";

export type LoadingType = "inline"|"block"|"fullscreen";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() observable: Observable<any>;
  
  @Input() templateRef: TemplateRef<any>;
  @Input() loadingType: LoadingType  = "inline";
  @Input() styleType: string = "primary";
  @Input() width: number = 12;
  @Input() height: number = 30;

  @Output() success: EventEmitter<null> = new EventEmitter();
  @Output() error: EventEmitter<null> = new EventEmitter();
  @Output() done: EventEmitter<null> = new EventEmitter();

  isLoading: boolean = false;
  subscription: Subscription;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.handleLoadingState(this.observable);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes["observable"];
    if (change && change.currentValue !== change.previousValue) {
      this.handleLoadingState(change.currentValue);
    }
  }

  handleLoadingState(loadingObservable: Observable<any>) {
    if (loadingObservable) {
      this.closeSubscription();
      this.isLoading = true;
      this.subscription = loadingObservable.subscribe(
        () => {
          this.success.emit();
        },
        (error: HttpErrorResponse) => {
          this.error.emit();
          this.alertService.turnErrorIntoAlert(error);
          this.onLoadingDone();
        },
        () => {
          this.done.emit();
          this.onLoadingDone();
        }
      );
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.closeSubscription();
  }
  closeSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  onLoadingDone() {
    this.isLoading = false;
  }

}
